<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-card-text" title="Informationen"></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-title class="subheading">
                    {{ userGroup.title }}
                  </v-card-title>
                  <v-card-subtitle>
                    {{ userGroup.description }}
                  </v-card-subtitle>
                  <v-card-text
                    ><span>
                      <v-icon small>mdi-sort-numeric-ascending</v-icon>
                      {{ userGroup.sortKey }}
                    </span>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-account-box-multiple"
                title="Zugehörige Benutzer"
              ></Subheader>
              <div class="mt-7">
                <v-data-table
                  :items="userGroupsQuery"
                  :headers="headers"
                  :sort-by="sortBy.toLowerCase()"
                  dense
                  :sort-desc="sortDesc"
                  class="elevation-2"
                  no-data-text="Keine Benutzer dieser Benutzergruppe zugewiesen"
                >
                  <template v-slot:[`item.access.status`]="{ item }">
                    <v-chip
                      :color="
                        item.access.status === 'activated'
                          ? 'green lighten-4'
                          : 'red lighten-4'
                      "
                      small
                      label
                      :class="
                        item.access.status === 'activated'
                          ? 'font-weight-medium green--text text--darken-4'
                          : 'font-weight-medium red--text text--darken-4'
                      "
                      >{{ textFromUserStatus(item.access.status) }}</v-chip
                    >
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { ADMIN } from "@/store/modules.js";
import { DELETE_USER_GROUP } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import {
  ADMINISTRATION_USERS_GROUPS_update,
  ADMINISTRATION_USERS_GROUPS_delete,
} from "@/data/permission-types.js";

export default {
  name: "user-group-details",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permission: `${ADMINISTRATION_USERS_GROUPS_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Löschen",
          permission: `${ADMINISTRATION_USERS_GROUPS_delete}`,
          icon: "mdi-delete",
          actionStyle: "textBtn",
          function: this.deleteItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      headers: [
        { text: "Benutzer", value: "user.displayName" },
        { text: "Status", value: "access.status" },
      ],

      sortDesc: true,
      sortBy: "user.displayName",

      userGroup: null,
      userGroupsQuery: [],
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["user/checkIfSupport"];
    },
    code() {
      return [{ title: "userGroup", data: this.userGroup }];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("userGroups")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.userGroup = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("membershipsPrivate")
        .where("group.id", "==", this.$route.params.itemId)
        .where("access.status", "in", ["activated", "deactivated"])
        .where("access.type", "==", "member")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              user: {
                displayName: doc.data().user.displayName,
                uid: doc.data().user.uid,
              },
              access: {
                status: doc.data().access.status,
              },
            };
            this.userGroupsQuery.push(data);
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    textFromUserStatus(item) {
      switch (item) {
        case "activated":
          return "Zugang aktiv";
        case "deactivated":
          return "Zugang deaktiviert";
        default:
          return "";
      }
    },
    editItem() {
      this.$router.push({
        name: "admin-users-tab-groups-group-edit",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
    deleteItem() {
      if (!this.userGroupsQuery.length > 0) {
        var res = confirm(
          "Bist Du sicher, dass Du diese Benutzergruppe löschen möchtest?"
        );
        if (res == true) {
          this.$store.dispatch(`${ADMIN}${DELETE_USER_GROUP}`, {
            organizationId: this.$route.params.organizationId,
            itemId: this.$route.params.itemId,
          });
        }
      } else {
        alert(
          "Fehler: Du kannst nur Benutzergruppen löschen, denen keine Benutzer zugeordnet sind."
        );
      }
    },
  },
};
</script>

<style></style>
