<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="supportAccounts"
      item-key="user.uid"
      multi-sort
      sort-by="user.displayName"
      class="elevation-2"
      no-data-text="Keine Support- und Servicekonten vorhanden"
      max-height="800px auto"
    >
      <template v-slot:[`item.access.status`]="{ item }">
        <v-chip
          :color="
            item.access.status === 'activated'
              ? 'green lighten-4'
              : 'red lighten-4'
          "
          small
          label
          :class="
            item.access.status === 'activated'
              ? 'font-weight-medium green--text text--darken-4'
              : 'font-weight-medium red--text text--darken-4'
          "
          >{{ textFromUserStatus(item.access.status) }}</v-chip
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "support-accounts-table",
  props: {
    supportAccounts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "user.displayName" },
        { text: "Status", value: "access.status" },
        { text: "Organisationsbeitritt", value: "user.memberSince" },
      ],
    };
  },
  created() {},
  computed: {},
  methods: {
    textFromUserStatus(item) {
      switch (item) {
        case "activated":
          return "Zugang aktiv";
        case "deactivated":
          return "Zugang deaktiviert";
        default:
          return "";
      }
    },
  },
};
</script>
