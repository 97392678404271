<template>
  <div>
    <NavigationBar
      titel="Benutzergruppe bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="updateItem"
        ref="form"
        v-model="helpers.formIsValid"
        class="col s8"
      >
        <group-editor
          v-model="userGroup"
          mode="edit"
          :membershipsInput="membershipsListFiltered"
        ></group-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { ADMIN } from "@/store/modules.js";
import { UPDATE_USER_GROUP, GET_ROLES } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import GroupEditor from "@/components/admin/users/groups/GroupEditor.vue";

export default {
  name: "admin-users-tab-groups-group-edit",
  components: {
    NavigationBar,
    GroupEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],
      userGroup: null,

      helpers: {
        formIsValid: false,
      },

      membershipList: [],
    };
  },
  computed: {
    membershipsListFiltered() {
      const input = this.membershipList;
      const groupItems = (arr) => {
        return arr.reduce((a, c) => {
          if (typeof a[c.group.title] === "undefined") a[c.group.title] = [];
          a[c.group.title] = [...a[c.group.title], c];
          return a;
        }, {});
      };
      const step1 = groupItems(input);
      const insertHeaders = (obj) => {
        return Object.entries(obj).reduce((a, [key, vals], i, d) => {
          let r = [
            {
              header: key,
            },
            ...vals,
          ];
          // this is needed to have the exact same array
          // that you presented as desirable output:
          // no divider at the last place
          if (i < d.length - 1) {
            r = [
              ...r,
              {
                divider: true,
              },
            ];
          }
          a = [...a, ...r];
          return a;
        }, []);
      };
      const step2 = insertHeaders(step1);
      return step2;
    },
    membersFiltered() {
      var input = this.membershipList;
      var filtered = input.filter((item) => {
        return item.group.id == this.$route.params.itemId;
      });
      return filtered;
    },
    roles() {
      return this.$store.state.admin.roles;
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_ROLES}`, {
        organizationId: this.$route.params.organizationId,
      });

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("userGroups")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.userGroup = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("membershipsPrivate")
        .where("access.status", "in", ["activated", "deactivated"])
        .where("access.type", "==", "member")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              group: {
                title: doc.data().group.title,
                id: doc.data().group.id,
              },
              user: {
                displayName: doc.data().user.displayName,
                //photoURL: doc.data().user.photoURL,
                uid: doc.data().user.uid,
              },
            };
            this.membershipList.push(object);
          });
          this.userGroup.members = this.membersFiltered.map(
            (item) => item.user.uid
          );
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    preventMemberDeletion(changed) {
      const previous = this.membersFiltered.map((item) => item.user.uid);
      // Create a copy of the previousInput array to avoid modifying the original
      const updatedInput = [...previous];

      // Loop through the changedInput array
      for (const item of changed) {
        // Check if the item is not already in the updatedInput
        if (!updatedInput.includes(item)) {
          // Add the item to updatedInput if it's not already present
          updatedInput.push(item);
        }
      }

      return updatedInput;
    },
    updateItem() {
      console.log(this.userGroup.members);
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${ADMIN}${UPDATE_USER_GROUP}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
          title: this.userGroup.title,
          description: this.userGroup.description,
          sortKey: this.userGroup.sortKey,
          membersIDArray: this.preventMemberDeletion(this.userGroup.members),
          roleIds: this.userGroup.roleIds,
        });
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
  },
};
</script>
