<template>
  <div>
    <NavigationBar
      titel="Benutzergruppe anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="createItem"
        ref="form"
        v-model="helpers.formIsValid"
        class="col s8"
      >
        <group-editor v-model="userGroup" mode="new"></group-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { ADMIN } from "@/store/modules.js";
import { CREATE_USER_GROUP, GET_ROLES } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import GroupEditor from "@/components/admin/users/groups/GroupEditor.vue";

export default {
  name: "userGroup-new",
  components: {
    NavigationBar,
    GroupEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],
      userGroup: {
        title: null,
        description: null,
        roleIds: null,
        sortKey: Number,
      },

      helpers: {
        formIsValid: false,
      },
    };
  },
  computed: {
    newSortKey() {
      return this.$store.state.admin.userGroups.length + 1;
    },
    roles() {
      return this.$store.state.admin.roles;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.sortKey = this.newSortKey;

      this.$store.dispatch(`${ADMIN}${GET_ROLES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${ADMIN}${CREATE_USER_GROUP}`, {
          organizationId: this.$route.params.organizationId,

          title: this.userGroup.title,
          sortKey: this.userGroup.sortKey,
          description: this.userGroup.description,
          roleIds: this.userGroup.roleIds,
        });
      } else {
        alert("Prüfe Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
