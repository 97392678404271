<template>
  <div>
    <NavigationBar titel="Details" showBackBtn></NavigationBar>
    <v-snackbar v-model="snackbar.model" timeout="5000"
      >{{ snackbar.text }}.</v-snackbar
    >
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-badge-account-horizontal-outline"
                title="Benutzerdaten"
              ></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-list>
                    <v-list-item>
                      <v-avatar
                        size="56"
                        color="secondary"
                        class="mr-5"
                        rounded
                      >
                        <span class="white--text">{{
                          initialien(data.user.displayName)
                        }}</span>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="title">{{
                          data.user.displayName
                        }}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ data.group.title }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="data.access.status !== 'archived'">
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-security" title="Berechtigungen"></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <div class="subtitle-1">
                            Die Berechtigungen eines Benutzers werden durch die
                            Benutzerrolle bestimmt, die ihm zugewiesen wurde.
                            Zusätzliche Rollen können individuell zugewiesen
                            werden.
                          </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <div class="subtitle-2">
                            Zugewiesene Rollen aus Benutzergruppe:
                          </div>
                          <v-chip
                            v-for="(item, index) in data.access.roles
                              .groupRolesIds"
                            :key="index"
                            label
                            class="mt-2 mr-2"
                            >{{ item }}</v-chip
                          >
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                          <v-autocomplete
                            v-model="data.access.roles.individualRolesIds"
                            :items="roles"
                            item-text="title"
                            item-value="meta.id"
                            item-disabled="meta.disabled"
                            clearable
                            outlined
                            chips
                            multiple
                            small-chips
                            label="Rollen auswählen"
                            class="mt-4"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn depressed color="success" @click="updateRole()">
                          <v-icon left> mdi-content-save </v-icon>
                          Speichern
                        </v-btn>
                      </v-card-actions>
                    </v-container>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="data.access.status !== 'archived'">
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-dots-horizontal"
                title="Aktionen"
              ></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-text>
                    <div class="subtitle-1">
                      Du kannst den Zugriff unabhängig von den Berechtigungen
                      für diesen Benutzer temporär sperren ohne diesen
                      unwiderruflich aus der Organisation zu entfernen.
                    </div>
                    <v-btn
                      color="warning"
                      depressed
                      text
                      class="mt-3"
                      @click="test()"
                      disabled
                    >
                      <v-icon left> mdi-shield-lock </v-icon>
                      Zugriff sperren
                    </v-btn>
                  </v-card-text>
                </v-card>
              </div>
              <div class="mt-7">
                <v-card>
                  <v-card-text>
                    <div class="subtitle-1">
                      Beim Entfernen der Mitgliedschaft eines Benutzers verliert
                      dieser alle Berechtigungen für diese Organisation.
                      Bestehende Daten in Verbindung mit dem Nutzer werden
                      zunächst archiviert und nach Ablauf der
                      Aufbewahrungsfristen gelöscht.
                    </div>
                    <v-btn
                      color="error"
                      depressed
                      text
                      class="mt-3"
                      :loading="loading"
                      @click="toggleConfirmationDialog()"
                    >
                      <v-icon left> mdi-delete </v-icon>
                      Mitgliedschaft entfernen
                    </v-btn>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
    <CustomDialog
      v-if="confirmationDialog.model"
      @close="toggleConfirmationDialog()"
      title="Mitgliedschaft entfernen"
      text="Bist Du sicher, dass Du diese Mitgliedschaft entfernen möchtest?"
      :loading="confirmationDialog.loading"
      :btnAction="deleteMembership"
      btnColor="error"
      width="s"
    ></CustomDialog>
    <router-view />
  </div>
</template>

<script>
import { db, functions } from "@/firebase";
import { ADMIN } from "@/store/modules.js";
import { GET_ROLES, UPDATE_MEMBERSHIP_ROLE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";

export default {
  name: "admin-users-tab-accounts-account-details",
  components: {
    NavigationBar,
    CustomDialog,
    Subheader,
    SupportTools,
  },
  data() {
    return {
      snackbar: { text: "", model: false },
      data: {
        user: {
          displayName: "",
          uid: "",
        },
        group: {
          title: "",
          id: "",
        },
        access: {
          status: "",
          type: "",
          disabled: false,
          roles: {
            groupRolesIds: [],
            individualRolesIds: [],
          },
          permissions: {
            allPermissionsIds: [],
            groupPermissionsIds: [],
            individualPermissionsIds: [],
          },
        },
      },
      confirmationDialog: {
        model: false,
        loading: false,
      },
      loading: false,
      // teams: [],
    };
  },
  computed: {
    roles() {
      return this.$store.state.admin.roles;
    },
    checkIfSupport() {
      return this.$store.getters["user/checkIfSupport"];
    },
    code() {
      return [{ title: "account", data: this.data }];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_ROLES}`, {
        organizationId: this.$route.params.organizationId,
      });

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("membershipsPrivate")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.data = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    updateRole() {
      this.$store
        .dispatch(`${ADMIN}${UPDATE_MEMBERSHIP_ROLE}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,

          roles: this.data.access.roles.individualRolesIds,
        })
        .then(() => {
          this.snackbar.text = "Berechtigungen aktualisiert";
          this.snackbar.model = true;
        })
        .catch((error) => {
          console.log(error);
          this.snackbar.text = error.message;
          this.snackbar.model = true;
        });
    },
    routeDetails(item) {
      this.$router.push({
        name: "my-organization-team-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
    createBorderColorStyle(farbe) {
      const style = "border-left: 12px solid " + farbe;
      return style;
    },
    initialien(fullName) {
      const namesArray = fullName.trim().split(" ");
      if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
      else
        return `${namesArray[0].charAt(0)}${namesArray[
          namesArray.length - 1
        ].charAt(0)}`;
    },
    toggleConfirmationDialog() {
      this.confirmationDialog.model = !this.confirmationDialog.model;
    },
    deleteMembership() {
      this.confirmationDialog.loading = true;
      const callFunction = functions.httpsCallable(
        "organization-deleteMembership"
      );
      callFunction({
        organizationId: this.$route.params.organizationId,
        userId: this.$route.params.itemId,
      })
        .then((result) => {
          if (result.data.success) {
            // this.showSuccess = true;
            this.confirmationDialog.model = false;
            this.confirmationDialog.loading = false;
            // this.snackbar = true;
            this.$router.push({
              name: "admin-users-tab-accounts",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          } else {
            this.errorMsg = "Fehler";
            this.confirmationDialog.loading = false;
          }
        })
        .catch((err) => {
          // handle error
          console.error(err);
          // this.errorMsg = err.message;
          this.confirmationDialog.loading = false;
        });
    },
  },
};
</script>
