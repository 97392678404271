<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="archivedAccounts"
      item-key="user.uid"
      multi-sort
      sort-by="user.displayName"
      class="elevation-2"
      no-data-text="Keine archivierten Benutzer vorhanden"
      max-height="800px auto"
    >
      <template v-slot:[`item.archiveTimestamp`]>
        <v-chip
          color="orange lighten-4"
          label
          small
          class="font-weight-medium orange--text text--darken-4"
          ><v-icon left small> mdi-alert-circle </v-icon>Nicht
          festgelegt</v-chip
        ></template
      >
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "archived-accounts-table",
  props: {
    archivedAccounts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "user.displayName" },
        { text: "Organisationsaustritt", value: "user.memberUntil" },
        { text: "Archivierungszeitpunkt", value: "archiveTimestamp" },
      ],
    };
  },
  created() {},
  computed: {},
};
</script>
