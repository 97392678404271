<template>
  <div>
    <v-chip
      :color="statusColor"
      label
      :small="small"
      class="font-weight-medium text--darken-4"
      :class="statusStyle"
      ><v-icon left small>{{ statusIcon }}</v-icon
      >{{ statusText }}</v-chip
    >
  </div>
</template>

<script>
export default {
  name: "invite-status-chip",
  props: {
    inviteStatus: {
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {
    this.statusStyle();
  },
  computed: {
    statusText() {
      switch (this.inviteStatus) {
        case "submitted":
          return "Wartet auf Verarbeitung";
        case "processing":
          return "Wird verarbeitet";
        case "invite sent":
          return "Gesendet";
        case "disabled":
          return "Deaktiviert";
        default:
          return "";
      }
    },
    statusColor() {
      switch (this.inviteStatus) {
        case "submitted":
          return "blue-grey lighten-4";
        case "processing":
          return "blue lighten-4";
        case "invite sent":
          return "green lighten-4";
        case "disabled":
          return "orange lighten-4";
        case "error":
          return "red lighten-4";
        default:
          return "";
      }
    },
    statusIcon() {
      switch (this.inviteStatus) {
        case "submitted":
          return "mdi-timer-sand";
        case "processing":
          return "mdi-server-network";
        case "invite sent":
          return "mdi-account-clock";
        case "disabled":
          return "mdi-account-lock";
        case "error":
          return "mdi-alert-circle";
        default:
          return "";
      }
    },
    statusStyle() {
      switch (this.inviteStatus) {
        case "submitted":
          return "blue-grey--text text--darken-4";
        case "processing":
          return "blue--text text--darken-4";
        case "invite sent":
          return "green--text text--darken-4";
        case "disabled":
          return "orange--text text--darken-4";
        case "error":
          return "red--text text--darken-4";
        default:
          return "";
      }
    },
  },
};
</script>
