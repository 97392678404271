<template>
  <div>
    <NavigationBar
      titel="Einladung bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form
              @submit.prevent="updateItem"
              v-model="formIsValid"
              class="col s8"
            >
              <v-col cols="12">
                <Subheader
                  icon="mdi-account-clock"
                  title="Allgemein"
                ></Subheader>
                <div class="mt-7">
                  <v-card>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="data.email"
                              :counter="32"
                              label="Bezeichnung"
                              required
                              :rules="[rules.email, rules.required]"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-autocomplete
                              v-model="data.group"
                              :items="userGroupsFiltered"
                              item-text="title"
                              item-value="meta.id"
                              return-object
                              hint="Benutzergruppen bestimmen die Zugehörigkeit innerhalb der Organisation. Berechtigungen werden entsprechend der Benutzergruppe vergeben."
                              required
                              :rules="[rules.required]"
                              persistent-hint
                              outlined
                              chips
                              small-chips
                              label="Benutzergruppe auswählen"
                              class="mt-4"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { ADMIN } from "@/store/modules.js";
import { GET_USER_GROUPS, UPDATE_INVITE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "admin-users-tab-accounts-invite-edit",
  components: {
    NavigationBar,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],

      rules: {
        required: (value) => !!value || "Erforderlich.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Ungültige E-Mail-Adresse.";
        },
      },
      formIsValid: false,
      data: null,
    };
  },
  computed: {
    benutzergruppen() {
      return this.$store.state.admin.userGroups;
    },
    userGroupsFiltered() {
      const input = this.$store.state.admin.userGroups;

      const filtered = input.map((item) => {
        const container = {};
        container.title = item.title;
        container.id = item.meta.id;
        return container;
      });

      return filtered;
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_USER_GROUPS}`, {
        organizationId: this.$route.params.organizationId,
      });

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("invites")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.data = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    updateItem() {
      if (this.formIsValid) {
        this.$store.dispatch(`${ADMIN}${UPDATE_INVITE}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
          email: this.data.email,
          group: this.data.group,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
