<template>
  <div>
    <NavigationBar
      titel="Details"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-badge-account"
                title="Informationen"
              ></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-title
                    >{{ data.email }}
                    <v-spacer></v-spacer>
                    <invite-status-chip
                      :inviteStatus="data.invite.status"
                    ></invite-status-chip>
                  </v-card-title>
                  <v-card-subtitle>
                    Zugeordnet zu Benutzergruppe
                    <strong>{{ data.group.title }}</strong>
                    <br />
                    Einladung via
                    <span
                      ><v-icon x-small class="mx-1">{{
                        iconFromInviteType(data.invite.type)
                      }}</v-icon
                      >{{ textFromInviteType(data.invite.type) }}</span
                    >
                    am {{ convertFromTimestamp(data.meta.createdAt) }}
                  </v-card-subtitle>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-dots-horizontal"
                title="Aktionen"
              ></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-text>
                    <div class="subtitle-1">
                      Die Gültigkeit der Einladung wird verlängert und eine neue
                      E-Mail wird an den Benutzer versendet.
                    </div>
                    <v-btn
                      color="primary"
                      depressed
                      text
                      disabled
                      class="mt-3"
                      @click="resendInvite()"
                    >
                      <v-icon left> mdi-email-sync </v-icon>
                      Einladung erneut senden
                    </v-btn>
                  </v-card-text>
                </v-card>
              </div>
              <div class="mt-7">
                <v-card>
                  <v-card-text v-if="statusCheck">
                    <div class="subtitle-1">
                      Du kannst die Einladung für diesen Benutzer temporär
                      zurückziehen und diese zu einem späteren Zeitpunkt wieder
                      aktivieren.
                    </div>
                    <v-btn
                      color="warning"
                      depressed
                      text
                      class="mt-3"
                      @click="disableInvite()"
                    >
                      <v-icon left> mdi-pause-octagon </v-icon>
                      Einladung temporär deaktivieren
                    </v-btn>
                  </v-card-text>
                  <v-card-text v-else>
                    <div class="subtitle-1">
                      Diese Einladung ist deaktiviert und kann nicht zum
                      Beitritt zu deiner Organisation verwendet werden. Um dem
                      Nutzer den Beitritt erneut zu ermöglichen, aktiviere die
                      Einladung.
                    </div>
                    <v-btn
                      color="warning"
                      depressed
                      text
                      class="mt-3"
                      @click="enableInvite()"
                    >
                      <v-icon left> mdi-alert-octagon </v-icon>
                      Einladung aktivieren
                    </v-btn>
                  </v-card-text>
                </v-card>
              </div>
              <div class="mt-7">
                <v-card>
                  <v-card-text>
                    <div class="subtitle-1">
                      Bei Entfernen der Einladung wird diese zurückgezogen und
                      verliert mit sofortiger Wirkung ihre Gültigkeit. Der
                      Benutzer kann nicht mittels der versendeten Einladung
                      deiner Organisation beitreten. Für einen Beitritt muss
                      eine neue Einladung versendet werden.
                    </div>
                    <v-btn
                      color="error"
                      depressed
                      text
                      class="mt-3"
                      @click="deleteItem()"
                    >
                      <v-icon left> mdi-close-octagon </v-icon>
                      Einladung zurückziehen
                    </v-btn>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import { db } from "@/firebase";
import { ADMIN } from "@/store/modules.js";
import { DELETE_INVITE, UPDATE_INVITE_STATUS } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import InviteStatusChip from "@/components/admin/users/accounts/InviteStatusChip.vue";
import { ADMINISTRATION_USERS_INVITES_update } from "@/data/permission-types.js";

export default {
  name: "admin-users-tab-accounts-invite-details",
  components: {
    NavigationBar,
    Subheader,
    InviteStatusChip,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${ADMINISTRATION_USERS_INVITES_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        { actionStyle: "clipboardBtn" },
      ],

      sortDesc: true,
      sortBy: "bezeichnung",
      data: null,
    };
  },
  computed: {
    statusCheck() {
      if (this.data.invite.status === "disabled") {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("invites")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.data = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    convertFromTimestamp(val) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return val.toDate().toLocaleDateString("de-DE", options);
    },
    iconFromInviteType(item) {
      switch (item) {
        case "mail":
          return "mdi-email";
        case "link":
          return "mdi-link";
        default:
          return "";
      }
    },
    textFromInviteType(item) {
      switch (item) {
        case "link":
          return "Einladungslink";
        case "mail":
          return "E-Mail";
        default:
          return "";
      }
    },
    resendInvite() {},

    enableInvite() {
      var res = confirm(
        "Diese Einladung wird wieder aktiviert und kann von Nutzern zum Beitritt zu deiner Organisation verwendet werden."
      );
      if (res == true) {
        this.updateInviteStatus("invite sent");
      }
    },
    disableInvite() {
      if (this.data.invite.status === "invite sent") {
        var res = confirm(
          "Bist Du sicher, dass Du diese Einladung temporär deaktivieren möchtest?"
        );
        if (res == true) {
          this.updateInviteStatus("disabled");
        }
      } else {
        alert("Fehler: Bitte kontaktiere den Support!");
      }
    },
    updateInviteStatus(statusVar) {
      this.$store.dispatch(`${ADMIN}${UPDATE_INVITE_STATUS}`, {
        organizationId: this.$route.params.organizationId,
        itemId: this.$route.params.itemId,
        invite: {
          status: statusVar,
        },
      });
    },
    editItem() {
      this.$router.push({
        name: "admin-users-tab-accounts-invite-edit",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
    deleteItem() {
      var res = confirm(
        "Bist Du sicher, dass Du diese Einladung löschen und damit zurückziehen möchtest?"
      );
      if (res == true) {
        this.$store.dispatch(`${ADMIN}${DELETE_INVITE}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        });
      }
    },
  },
};
</script>
