<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader icon="mdi-folder-account" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model.trim="userGroup.title"
                          :counter="32"
                          label="Bezeichnung"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model.number="userGroup.sortKey"
                          label="Sortierschlüssel"
                          prepend-inner-icon="mdi-sort-numeric-ascending"
                          type="number"
                          hint="Benutzergruppen werden aufsteigend nach ihrem Sortierschlüssel sortiert."
                          persistent-hint
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model.trim="userGroup.description"
                          counter
                          clearable
                          no-resize
                          label="Beschreibung"
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col v-if="mode === 'new'" cols="12">
            <v-alert border="left" colored-border type="info" elevation="2">
              Du musst zunächst eine Benutzergruppe anlegen, um dieser
              Benutzerkonten oder Rollen zuzuordnen.
            </v-alert>
          </v-col>
          <v-col v-else cols="12">
            <Subheader
              icon="mdi-folder-account"
              title="Zugehörige Benutzer"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          v-model="userGroup.members"
                          :items="membershipsInput"
                          chips
                          small-chips
                          outlined
                          hint="Ein Benutzer kann nur einer Benutzergruppe zugehören. Sobald ein Nutzer dieser Benutzergruppe hinzugefügt wird, wird die Zugehörigkeit zu einer anderen Benutzergruppe entfernt! Ein Benutzer muss einer Benutzergruppe angehören und kann daher nicht von einer Benutzergruppe entfernt, sondern nur einer neuen zugewiesen werden."
                          persistent-hint
                          label="Benutzer auswählen"
                          item-text="user.displayName"
                          item-value="user.uid"
                          counter
                          multiple
                        >
                          <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-item-content>{{
                                data.item
                              }}</v-list-item-content>
                            </template>
                            <template v-else>
                              <v-avatar color="secondary" size="38">
                                <span class="white--text">{{
                                  initials(data.item.user.displayName)
                                }}</span>
                              </v-avatar>
                              <v-list-item-content class="ml-4">
                                <v-list-item-title>{{
                                  data.item.user.displayName
                                }}</v-list-item-title>
                                <v-list-item-subtitle>{{
                                  data.item.group.title
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          v-model="userGroup.roleIds"
                          :items="roles"
                          item-text="title"
                          item-value="meta.id"
                          item-disabled="meta.disabled"
                          outlined
                          chips
                          small-chips
                          multiple
                          clearable
                          persistent-hint
                          deletable-chips
                          hint="Benutzerkonten dieser Benutzergruppe erhalten automatisch alle Berechtigungen aus den ausgewählten Rollen zugewiesen."
                          label="Rollen auswählen"
                          class="mt-4"
                        >
                          <template v-slot:item="{ item, attrs, on }">
                            <v-list-item
                              v-on="on"
                              v-bind="attrs"
                              #default="{ active }"
                            >
                              <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.title }}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{
                                  item.description
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>

                          <!-- <template v-slot:item="data">
                          <template>
                            <v-list-item-action
                              ><v-simple-checkbox v-mo></v-simple-checkbox
                            ></v-list-item-action>
                            <v-list-item-content class="ml-4">
                              <v-list-item-title
                                v-text="data.item.title"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                v-text="data.item.description"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                          </template> -->
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
export default {
  name: "user-group-editor",
  props: {
    userGroup: {},
    membershipsInput: {
      type: Array,
    },
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  model: {
    prop: "userGroup",
    event: "userGroupChange",
  },
  computed: {
    userGroupLocal: {
      get: function () {
        return this.userGroup;
      },
      set: function (value) {
        this.$emit("userGroupChange", value);
      },
    },
    roles() {
      return this.$store.state.admin.roles;
    },
  },
  methods: {
    initials(fullName) {
      const namesArray = fullName.trim().split(" ");
      if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
      else
        return `${namesArray[0].charAt(0)}${namesArray[
          namesArray.length - 1
        ].charAt(0)}`;
    },
  },
};
</script>
