<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="activeAccounts"
      item-key="user.uid"
      multi-sort
      sort-by="user.displayName"
      class="elevation-2"
      max-height="800px auto"
      @click:row="viewAccount"
    >
      <template v-slot:[`item.profil`]="{ item }">
        <v-badge
          bordered
          overlap
          dot
          bottom
          :color="avatarColorFromStatus(item.access.status)"
        >
          <v-avatar
            size="34"
            :color="
              $vuetify.theme.dark
                ? 'rgba(255, 255, 255, 0.12)'
                : 'grey lighten-2'
            "
            class="font-weight-medium"
            >{{ initials(item.user.displayName) }}</v-avatar
          >
        </v-badge>
      </template>
      <template v-slot:[`item.access.status`]="{ item }">
        <v-chip
          :color="
            item.access.status === 'activated'
              ? 'green lighten-4'
              : 'red lighten-4'
          "
          small
          label
          :class="
            item.access.status === 'activated'
              ? 'font-weight-medium green--text text--darken-4'
              : 'font-weight-medium red--text text--darken-4'
          "
          >{{ textFromUserStatus(item.access.status) }}</v-chip
        >
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="red darken-1" @click="viewAccount(item)">
          mdi-chevron-right
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "active-accounts-table",
  props: {
    activeAccounts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Profil", value: "profil", sortable: false },
        { text: "Name", value: "user.displayName" },
        { text: "Benutzergruppe", value: "group.title" },
        { text: "Status", value: "access.status" },
        { text: "Organisationsbeitritt", value: "user.memberSince" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  created() {},
  computed: {},
  methods: {
    initials(fullName) {
      const namesArray = fullName.trim().split(" ");
      if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
      else
        return `${namesArray[0].charAt(0)}${namesArray[
          namesArray.length - 1
        ].charAt(0)}`;
    },
    avatarColorFromStatus(item) {
      switch (item) {
        case "activated":
          return "green";
        case "deactivated":
          return "warning";
        case "error":
          return "error";
        default:
          return "";
      }
    },
    textFromUserStatus(item) {
      switch (item) {
        case "activated":
          return "Zugang aktiv";
        case "deactivated":
          return "Zugang deaktiviert";
        default:
          return "";
      }
    },
    viewAccount(item) {
      this.$router.push({
        name: "admin-users-tab-accounts-account-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.user.uid,
        },
      });
    },
  },
};
</script>
